var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"user-list"}},[_c('v-row',{staticClass:"mb-5"},_vm._l((_vm.totalLocal),function(total){return _c('v-col',{key:total.total,attrs:{"cols":"12","sm":"6","md":"3"}},[_c('v-card',[_c('v-card-text',{staticClass:"d-flex align-center justify-space-between pa-4"},[_c('div',[_c('h2',{staticClass:"font-weight-semibold mb-1"},[_vm._v(" "+_vm._s(total.total)+" ")]),_c('span',[_vm._v(_vm._s(total.title))])])])],1)],1)}),1),_c('v-card',[_c('v-card-title',[_vm._v(" Search & Filter ")]),_c('v-row',{staticClass:"px-2 ma-0"},[_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{staticClass:"user-search me-3 mb-4",attrs:{"placeholder":"Search (Number), User account:email@,phone>6 digit","outlined":"","hide-details":"","dense":""},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}})],1),_c('v-col',{staticClass:"d-flex",attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.statusOptions,"outlined":"","dense":"","hide-details":"","multiple":"","placeholder":"Select Status","menu-props":{ maxHeight: '400' }},on:{"change":_vm.loadApplications},model:{value:(_vm.statusInFilter),callback:function ($$v) {_vm.statusInFilter=$$v},expression:"statusInFilter"}})],1)],1),_c('v-divider',{staticClass:"mt-4"}),_c('v-data-table',{attrs:{"headers":_vm.tableColumns,"items":_vm.applicationListTable,"options":_vm.options,"server-items-length":_vm.totalListTable,"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.number))])]}},{key:"item.participant",fn:function(ref){
var item = ref.item;
return [(item.quota.group.participant)?[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(item.quota.group.participant.last_name))]),_c('span',[_vm._v(_vm._s(item.quota.group.participant.first_name))])]:_vm._e()]}},{key:"item.participant_contact",fn:function(ref){
var item = ref.item;
return [(item.quota.group.participant)?[_c('div',[_vm._v(_vm._s(item.quota.group.participant.email))]),_c('div',[_vm._v(_vm._s(item.quota.group.participant.phone))])]:_vm._e()]}},{key:"item.user_id",fn:function(ref){
var item = ref.item;
return [(item.quota.group.participant)?[_c('div',[_vm._v(_vm._s(item.quota.user.email))]),_c('div',[_vm._v(_vm._s(item.quota.user.phone))])]:_vm._e()]}},{key:"item.destination_id",fn:function(ref){
var item = ref.item;
return [(item.destination)?_c('span',[_c('div',[_vm._v(_vm._s(_vm.t(item.destination.name)))]),_c('div',{staticClass:"text-wrap min-w-400"},[_vm._v(_vm._s(_vm.t(item.destination.address)))])]):_vm._e()]}},{key:"item.rechargeable_id",fn:function(ref){
var item = ref.item;
return [(item.quota.rechargeable)?_c('div',{staticClass:"text-wrap-pretty min-w-200"},[_vm._v(" "+_vm._s(item.quota.rechargeable.name.en)+" ")]):_vm._e()]}},{key:"item.inventory_id",fn:function(ref){
var item = ref.item;
return [(item.inventory_snapshot && item.inventory_snapshot.info)?_c('span',[_vm._v(_vm._s(_vm._f("date")(item.inventory_snapshot.info.started_at)))]):_vm._e()]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.mapStatus(item.status)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',[(_vm.canRefund(item))?_c('v-list-item',{on:{"click":function($event){return _vm.setRefund(item)}}},[_c('v-list-item-title',[_c('span',[_vm._v("Refund")])])],1):_vm._e()],1)],1)]}}],null,true),model:{value:(_vm.selectedRows),callback:function ($$v) {_vm.selectedRows=$$v},expression:"selectedRows"}})],1),_c('v-dialog',{staticClass:"v-dialog-sm",attrs:{"width":"700"},model:{value:(_vm.isDialogVisible),callback:function ($$v) {_vm.isDialogVisible=$$v},expression:"isDialogVisible"}},[(_vm.dialog === 'refund')?_c('refund-dialog',{attrs:{"application":_vm.resource},on:{"refunded":_vm.refunded}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }