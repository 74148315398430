<template>
  <div id="user-list">
    <!-- user total card -->
    <v-row class="mb-5">
      <v-col
        v-for="total in totalLocal"
        :key="total.total"
        cols="12"
        sm="6"
        md="3"
      >
        <v-card>
          <v-card-text class="d-flex align-center justify-space-between pa-4">
            <div>
              <h2 class="font-weight-semibold mb-1">
                {{ total.total }}
              </h2>
              <span>{{ total.title }}</span>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- list filters -->
    <v-card>
      <v-card-title>
        Search &amp; Filter
      </v-card-title>

      <v-row class="px-2 ma-0">
        <!-- search filter -->
        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search (Number), User account:email@,phone>6 digit"
            outlined
            hide-details
            dense
            class="user-search me-3 mb-4"
          >
          </v-text-field>
        </v-col>

        <v-col
          cols="12"
          sm="6"
          class="d-flex"
        >
          <v-select
          v-model="statusInFilter"
          :items="statusOptions"
          outlined
          dense
          hide-details
          multiple
          placeholder="Select Status"
          :menu-props="{ maxHeight: '400' }"
          @change="loadApplications"
        ></v-select>
        </v-col>
      </v-row>

      <v-divider class="mt-4"></v-divider>

      <!-- table -->
      <v-data-table
        v-model="selectedRows"
        :headers="tableColumns"
        :items="applicationListTable"
        :options.sync="options"
        :server-items-length="totalListTable"
        :loading="loading"
      >
        <template #[`item.id`]="{item}">
          <span>{{ item.number }}</span>
        </template>
      <!-- user -->
        <template #[`item.participant`]="{item}">
          <template v-if="item.quota.group.participant">
            <span class="mr-2">{{  item.quota.group.participant.last_name }}</span>
            <span>{{  item.quota.group.participant.first_name }}</span>
          </template>
        </template>

        <template #[`item.participant_contact`]="{item}">
          <template v-if="item.quota.group.participant">
            <div>{{  item.quota.group.participant.email }}</div>
            <div>{{  item.quota.group.participant.phone }}</div>
          </template>
        </template>

        <template #[`item.user_id`]="{item}">
          <template v-if="item.quota.group.participant">
            <div>{{  item.quota.user.email }}</div>
            <div>{{  item.quota.user.phone }}</div>
          </template>
        </template>
        
        <template #[`item.destination_id`]="{item}">
          <span v-if="item.destination">
            <div>{{ t(item.destination.name) }}</div>
            <div class="text-wrap min-w-400">{{ t(item.destination.address) }}</div>
          </span>
        </template>

        <template #[`item.rechargeable_id`]="{item}">
          <div class="text-wrap-pretty min-w-200" v-if="item.quota.rechargeable">
            {{ item.quota.rechargeable.name.en }}
          </div>
        </template>

        <template #[`item.inventory_id`]="{item}">
          <span v-if="item.inventory_snapshot && item.inventory_snapshot.info">{{ item.inventory_snapshot.info.started_at | date }}</span>
        </template>

        <template #[`item.status`]="{item}">
          <span>{{ mapStatus(item.status) }}</span>
        </template>

        <!-- action -->
        <template #[`item.actions`]="{item}">
            <v-menu
              bottom
              left
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="setRefund(item)" v-if="canRefund(item)">
                  <v-list-item-title>
                    <span>Refund</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

      </v-data-table>
    </v-card>

    <v-dialog
      v-model="isDialogVisible"
      class="v-dialog-sm"
      width="700"
    >
      <refund-dialog v-if="dialog === 'refund'" :application="resource" @refunded="refunded" />
    </v-dialog>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { t } from '@/plugins/i18n'

import { mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'
import RefundDialog from './RefundDialog.vue'
import useApplications from './useApplications'

export default {
  components: { RefundDialog },
  filters: {
    date: value => {
      if (!value) return

      return moment(value).format('YYYY-MM-DD HH:mm')
    },
  },
  setup() {
    const resource = ref(false)
    const isDialogVisible = ref(false)
    const dialog = ref('')

    const canRefund = application => {
      return ['completed'].includes(application.status)
    }

    const setRefund = (item) => {
      resource.value = item
      isDialogVisible.value = true
      dialog.value = 'refund'
    }

    const refunded = () => {
      loadApplications()
      isDialogVisible.value = false
    }

    const {
      applicationListTable,
      tableColumns,
      searchQuery,
      statusInFilter,
      statusOptions,
      totalListTable,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadApplications,
      mapStatus
    } = useApplications({
      only_trashed: 1,
    })

    return {
      t,
      
      applicationListTable,
      tableColumns,
      searchQuery,
      statusInFilter,
      statusOptions,
      totalListTable,
      mapStatus,

      // categoriesOptions,
      loading,
      options,
      totalLocal,
      selectedRows,

      loadApplications,

      isDialogVisible,
      dialog,
      canRefund,
      setRefund,
      refunded,
      resource,

      icons: {
        mdiDotsVertical,
      }
    }
  },
}
</script>
