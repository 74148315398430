<template>
    <v-card>
      <v-card-title class="font-weight-semibold">
        Refund Appointment
      </v-card-title>
      <v-card-text>
        <p>Are you sure you want to refund this appointment?</p>

        <p>Upon successfully refund, the user will get an extra quota to spend.</p>

        <p><b>Appointment Number:</b> {{ application.number }}</p>
      </v-card-text>

      <v-card-actions>
        <v-spacer />

        <v-btn
          type="button"
          class="mr-4"
          color="success"
          :loading="loading"
          :disabled="loading"
          @click="refundApplication"
        >
          Confirm
        </v-btn>
      </v-card-actions>
    </v-card>
</template>

<script>
import AppointmentAPI from '@/api/appointment'
import { ref } from '@vue/composition-api'

export default {
  props: {
    application: {
      type: Object,
      required: true,
      default: {},
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const isDialogVisible = ref(false)

    const refundApplication = async () => {
      loading.value = true

      try {
        await AppointmentAPI.refund(props.application.uuid)

        reset()

        toggleDialog(false)

        emit('refunded')
      } catch (err) {
        console.log(err)

        alert('Something went wrong!')
      }
    }

    const toggleDialog = status => {
      isDialogVisible.value = status
    }

    const reset = () => {
      loading.value = false
    }

    return {
      loading,
      isDialogVisible,

      toggleDialog,
      refundApplication
    }
  },
}
</script>
