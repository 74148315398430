import axios from '@axios'

export default {
  async getAll(params = {}) {
    const endpoint = '/merchant/user-quotas'

    const { status, data } = await axios.get(endpoint, { params })

    if (status === 200) {
      return data.data
    }

    return null
  },

  async getById(id) {
    const endpoint = `/merchant/user-quota-applications/${id}`

    const { status, data } = await axios.get(endpoint)

    if (status === 200) {
      return data.data
    }

    return null
  },

  async confirm(uuid) {
    const endpoint = `/merchant/user-quota-applications/${uuid}/accept`

    const { status, data } = await axios.post(endpoint)

    if (status === 202) {
      return data.data
    }

    return null
  },

  async cancel(uuid) {
    const endpoint = `/merchant/user-quota-applications/${uuid}/reject`

    const { status, data } = await axios.post(endpoint)

    if (status === 202) {
      return data.data
    }

    return null
  },

  async reschedule(uuid, params) {
    const endpoint = `/merchant/user-quota-applications/${uuid}/inventory`

    const { status, data } = await axios.put(endpoint, params)

    if (status === 202) {
      return data.data
    }

    return null
  },

  async getTimeSlots(uuid, params) {
    const endpoint = `/merchant/user-quotas/${uuid}/time-slots`

    const { status, data } = await axios.get(endpoint, { params })

    if (status === 200) {
      return data.data
    }

    return null
  },

  async complete(uuid, params) {
    const endpoint = `/merchant/user-quota-applications/${uuid}/complete`

    const { status, data } = await axios.post(endpoint, params)

    if (status === 202) {
      return data.data
    }

    return null
  },

  async refund(uuid, params) {
    const endpoint = `/merchant/user-quota-applications/${uuid}/refund`

    const { status, data } = await axios.post(endpoint, params)

    if (status === 202) {
      return data.data
    }

    return null
  },
}
